import type React from "react";
import { useEffect, useState } from "react";

import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import invariant from "ts-invariant";

import { translateError } from "api/errors/helper";
import { useDeleteAppointment } from "api/hooks/useDeleteAppointment";
import { useDeleteAppointmentManager } from "api/hooks/useDeleteAppointmentManager";
import useGetAppointments from "api/hooks/useGetAppointments";
import useGetHcpServices from "api/hooks/useGetHcpServices";
import useGetManagerPatients from "api/hooks/useGetManagerPatients";
import useGetManagerServices from "api/hooks/useGetManagerServices";
import useGetPatients from "api/hooks/useGetPatients";
import { usePostAppointment } from "api/hooks/usePostAppointment";
import { usePostAppointmentManager } from "api/hooks/usePostAppointmentManager";
import { usePutAppointment } from "api/hooks/usePutAppointment";
import { usePutAppointmentManager } from "api/hooks/usePutAppointmentManager";
import type { HcpService, HcpServiceManager } from "api/schemas/HcpService";
import type { NewAppointment } from "api/schemas/NewAppointment";
import type { SelectedDate } from "contexts/CalendarContext";
import { useProfileContext } from "contexts/ProfileContext";
import { MUIDropdown, MUITextInput } from "shared/atoms/inputs";
import { Notification } from "shared/atoms/Notification";
import useLocalizedDate from "utils/date";
import useLocale from "utils/date/useLocale";
import { reportError } from "utils/errorReporting";
import { userIsAdmin, userIsTherapist } from "utils/profile/profileHelper";

import { generateAvailabilityOptions } from "../../helpers";
import type { FormStates } from "../../helpers/misc";
import AppointmentInterval from "../AppointmentInterval";
import type { SetSelectArgs } from "../Calendar/Calendar";
import {
  convertDateStringToObject,
  formDateToJSDates,
  generateOptions,
  generateParticipantOptionsFull,
} from "../helpers";
import { MultiselectField } from "../MultiselectField";

import { FormFooter } from "./FormFooter";
import { getDefaultDate } from "./getDefaultDate";
import { hasConflict } from "./hasConflict";
import { Container, Input, Wrapper } from "./styles";

export type Service = HcpService | HcpServiceManager;

export type FormData = {
  date: string;
  end_time: number;
  health_care_provider_service_id: string;
  medium: string;
  note: string;
  patient_id: string[];
  personnel: number;
  start_time: number;
};

interface Props {
  defaultServiceID: number | undefined;
  event?: NewAppointment | null;
  selectedDate?: SelectedDate | null;
  selectedPatient?: string | null;
  onClose: (booked?: boolean) => void;
  refetchAppointments?: () => void;
  setSelect?: (args: SetSelectArgs) => void;
}

export const BookAppointmentForm: React.VFC<Props> = ({
  defaultServiceID,
  event,
  selectedDate,
  selectedPatient,
  onClose,
  refetchAppointments,
  setSelect,
}) => {
  const { profile } = useProfileContext();
  const { t } = useTranslation();
  const { format, isValid } = useLocalizedDate();
  const locale = useLocale();
  const [services, setServices] = useState<Service[]>([]);
  const [editMode] = useState(!!event);
  const [bookingWarning, setBookingWarning] = useState("");

  invariant(profile);
  const isAdmin = userIsAdmin(profile);
  const isTherapist = userIsTherapist(profile);

  const { data: therapistPatients } = useGetPatients(profile.id, { enabled: isTherapist });
  const { data: adminPatients } = useGetManagerPatients(profile.id, { enabled: isAdmin });
  const allPatients = [...(therapistPatients || []), ...(adminPatients || [])]; // a pt can be both pt and admin
  const patients = allPatients.filter((patient, index, array) => array.findIndex(p => p.id === patient.id) === index); // remove duplicates

  const { data: hcpServices = [] } = useGetHcpServices(profile.id, {
    enabled: !isAdmin,
  });
  const { data: servicesManager = [] } = useGetManagerServices(profile.id, {
    enabled: isAdmin,
  });

  useEffect(() => {
    if (!isAdmin && hcpServices.length > 0) {
      setServices(hcpServices);
    } else if (isAdmin && servicesManager.length > 0) {
      setServices(servicesManager);
    }
  }, [isAdmin, hcpServices, servicesManager]);

  // API - health_care_professionals
  const postAppointmentMutation = usePostAppointment();
  const putAppointmentMutation = usePutAppointment();
  const deleteAppointmentMutation = useDeleteAppointment();

  // API - manager
  const postAppointmentManagerMutation = usePostAppointmentManager();
  const putAppointmentManagerMutation = usePutAppointmentManager();
  const deleteAppointmentManagerMutation = useDeleteAppointmentManager();

  const [selectedStartTime, setSelectedStartTime] = useState<number | undefined>(undefined);
  const [selectedEndTime, setSelectedEndTime] = useState<number | undefined>(undefined);
  const [formState, setFormState] = useState<FormStates>("default");
  const [error, setError] = useState<string | null>(null);
  const [duration, setDuration] = useState<number | undefined>(undefined);
  const [personnelOptions, setPersonnelOptions] = useState(
    isTherapist ? [{ label: `${profile?.first_name} ${profile?.last_name}`, value: profile.id }] : []
  );

  const getDefaultPatientID = () => {
    if (selectedPatient) {
      return [selectedPatient];
    }
    return event?.participants?.[0]?.id.toString() ? [event?.participants?.[0]?.id.toString()] : [];
  };

  const form = useForm<FormData>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      date: getDefaultDate(event?.start_time),
      health_care_provider_service_id: event?.health_care_provider_service_id?.toString() ?? "",
      medium: event?.medium ?? "",
      note: event?.note ?? "",
      patient_id: getDefaultPatientID(),
      personnel: event?.health_care_professional_id ?? personnelOptions[0]?.value,
      start_time: event?.start_time ? convertDateStringToObject(event.start_time).startTime : undefined,
      end_time: event?.end_time ? convertDateStringToObject(event.end_time).startTime : undefined,
    },
  });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = form;

  const selectedPersonnel = watch("personnel");
  const selectedService = watch("health_care_provider_service_id");
  const hcpService = services.find(service => service.id === Number(selectedService));
  const watchDate = watch("date");
  const watchStartTime = watch("start_time");
  const watchEndTime = watch("end_time");

  // set default value for services dropdown
  useEffect(() => {
    if (!selectedService && defaultServiceID) {
      setValue("health_care_provider_service_id", String(defaultServiceID));
    } else if (!selectedService && services.length > 0) {
      setValue("health_care_provider_service_id", String(services[0].id));
    }
  }, [defaultServiceID, services, selectedService]);

  const { data: appointmentsData, isSuccess } = useGetAppointments(
    {
      userId: profile.id,
      startDate: encodeURIComponent(
        isValid(new Date(watchDate)) ? new Date(watchDate).toISOString() : new Date().toISOString()
      ),
      view: "day",
      isAdmin,
    },
    { enabled: !!watchDate && isValid(new Date(watchDate)) }
  );

  const appointments = appointmentsData?.appointments ?? [];
  const bookingIntervals = appointmentsData?.booking_intervals ?? [];

  useEffect(() => {
    if (watchDate && isValid(new Date(watchDate))) {
      if (setSelect) {
        const { startDate, endDate } = formDateToJSDates({
          date: watchDate,
          startTime: watchStartTime ?? 0,
          endTime: watchEndTime ?? 0,
        });
        setSelect({ start: startDate, end: endDate });
      }

      if (isSuccess && watchStartTime && watchEndTime) {
        const conflict = hasConflict({
          date: watchDate,
          startTime: watchStartTime,
          endTime: watchEndTime,
          appointments,
          bookingIntervals,
          personnelOptions,
          selectedAppointment: event,
          selectedPersonnel,
          userID: profile.id,
        });

        setBookingWarning(conflict);
      }
    }
  }, [selectedPersonnel, watchDate, watchStartTime, watchEndTime]);

  useEffect(() => {
    const dateStart = selectedDate && convertDateStringToObject(selectedDate.startStr);
    const dateEnd = selectedDate && convertDateStringToObject(selectedDate.endStr);

    if (dateStart?.startTime) {
      setSelectedStartTime(dateStart.startTime);
    }
    if (dateEnd?.startTime) {
      setSelectedEndTime(dateEnd.startTime);
    }
    if (dateStart?.date) {
      setValue("date", dateStart.date);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (!hcpService) {
      return;
    }

    // Set default duration to service's duration
    setDuration(hcpService?.duration);

    // Set default medium based on service's mode
    if (hcpService?.mode === "digital") {
      setValue("medium", "video");
    } else if (hcpService?.mode === "physical") {
      setValue("medium", "face_to_face");
    }

    // Set personnel options based on service's availble health_care_professionals
    if ("health_care_professionals" in hcpService && hcpService.health_care_professionals) {
      setPersonnelOptions(
        hcpService.health_care_professionals.map(professional => ({
          label: `${professional?.full_name}`,
          value: professional.id,
        }))
      );
    }
  }, [hcpService]);

  const serviceOptions = generateOptions({ array: services, labelKey: "name", valueKey: "id" });

  const mediumOptions =
    hcpService?.mode === "digital"
      ? [
          { label: t("booking.form.medium.phone"), value: "phone" },
          { label: t("booking.form.medium.video"), value: "video" },
        ]
      : [{ label: t("booking.form.medium.face_to_face"), value: "face_to_face" }];

  const participantOptionsFull = generateParticipantOptionsFull(patients);

  const onSetupCallback = (successMessage?: FormStates) => {
    return {
      onSuccess: () => {
        if (refetchAppointments) refetchAppointments();
        setFormState(successMessage ?? "success");
        if (onClose) setTimeout(() => onClose(true), 3000);
      },
      onError(e: unknown) {
        if (axios.isAxiosError(e)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setError(t(translateError(e.response?.data.error_code)));
        }
        setFormState("default");
        if (e instanceof Error || typeof e === "string") {
          reportError("BookingForm.tsx", e);
        }
      },
    };
  };

  const onSubmit = handleSubmit(formData => {
    const { startDate, endDate } = formDateToJSDates({
      date: formData.date,
      startTime: formData.start_time,
      endTime: formData.end_time,
    });
    const startDateISOStr = startDate.toISOString();
    const endDateISOStr = endDate.toISOString();

    setFormState("saving");
    setError(null);

    const data = {
      end_time: endDateISOStr,
      health_care_provider_service_id: parseInt(formData.health_care_provider_service_id, 10),
      medium: formData.medium,
      note: formData.note,
      patient_id: parseInt(formData.patient_id[0], 10),
      start_time: startDateISOStr,
    };
    if (editMode && event) {
      putAppointmentMutation.mutateAsync(
        {
          data,
          hcProfId: profile.id,
          appointmentId: event.id,
        },
        onSetupCallback()
      );
    } else {
      postAppointmentMutation.mutateAsync(
        {
          data,
          hcProfId: profile.id,
        },
        onSetupCallback()
      );
    }
  });

  const onSubmitManager = handleSubmit(formData => {
    const { startDate, endDate } = formDateToJSDates({
      date: formData.date,
      startTime: formData.start_time,
      endTime: formData.end_time,
    });
    const startDateISOStr = startDate.toISOString();
    const endDateISOStr = endDate.toISOString();

    setFormState("saving");
    setError(null);

    const data = {
      end_time: endDateISOStr,
      health_care_professional_id: formData.personnel,
      health_care_provider_service_id: parseInt(formData.health_care_provider_service_id, 10),
      medium: formData.medium,
      note: formData.note,
      patient_id: parseInt(formData.patient_id[0], 10),
      start_time: startDateISOStr,
    };
    if (editMode && event) {
      putAppointmentManagerMutation.mutateAsync(
        {
          data,
          managerId: profile.id,
          appointmentId: event.id,
        },
        onSetupCallback()
      );
    } else {
      postAppointmentManagerMutation.mutateAsync(
        {
          data,
          managerId: profile.id,
        },
        onSetupCallback()
      );
    }
  });

  const deleteAppointment = () => {
    if (event) {
      if (isAdmin) {
        deleteAppointmentManagerMutation.mutateAsync(
          { managerId: profile.id, appointmentId: event.id },
          onSetupCallback()
        );
      } else {
        deleteAppointmentMutation.mutateAsync({ hcProfId: profile.id, appointmentId: event.id }, onSetupCallback());
      }
    }
  };

  const renderContent = () => {
    return (
      <FormProvider {...form}>
        <form onSubmit={isAdmin ? onSubmitManager : onSubmit} data-testid="booking-form">
          <Input>
            <MUIDropdown
              label={t("booking.form.service")}
              name="health_care_provider_service_id"
              options={serviceOptions}
              required
              error={errors.health_care_provider_service_id && t("errors.field.required")}
            />
          </Input>

          <Input>
            <MUIDropdown
              label={t("booking.form.mode")}
              name="medium"
              options={mediumOptions ?? []}
              disabled={!selectedService}
              required
              error={errors.medium && t("errors.field.required")}
            />
          </Input>

          <Input className="date-and-time">
            <Controller
              name="date"
              control={control}
              render={({ field: { onChange, value } }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                  <Wrapper>
                    <KeyboardDatePicker
                      autoOk
                      name="date"
                      id="date-picker"
                      variant="inline"
                      inputVariant="outlined"
                      invalidDateMessage={t("errors.invalid_date")}
                      minDateMessage={t("errors.past_date")}
                      margin="none"
                      format="yyyy-MM-dd"
                      minDate={new Date()}
                      value={value}
                      onChange={input => {
                        if (input && isValid(input)) {
                          onChange(format(input, "Y-MM-dd"));
                        } else {
                          onChange(input);
                        }
                      }}
                      style={{ width: "100%" }}
                    />
                  </Wrapper>
                </MuiPickersUtilsProvider>
              )}
            />
          </Input>

          <Input>
            <AppointmentInterval
              options={generateAvailabilityOptions(hcpService as HcpService, 5)}
              setValue={setValue}
              getValues={getValues}
              duration={duration}
              selectedStartTime={selectedStartTime}
              selectedEndTime={selectedEndTime}
              errors={errors}
            />
          </Input>

          <Input>
            <MUIDropdown
              label={t("booking.form.personnel")}
              name="personnel"
              options={personnelOptions ?? []}
              disabled={!isAdmin || Boolean(selectedPatient)}
              error={errors.personnel && t("errors.field.required")}
            />
          </Input>

          <Input>
            <MultiselectField
              name="patient_id"
              label={t("booking.form.participants")}
              optionsFull={participantOptionsFull}
              nonDeletable
              singleSelect
              required
              disabled={editMode || Boolean(selectedPatient)}
              error={errors.patient_id && t("errors.field.required")}
            />
          </Input>

          <Input>
            <MUITextInput label={t("booking.form.note")} multiline name="note" />
          </Input>

          {bookingWarning && (
            <Notification variant="warning" style={{ margin: "10px 5px" }}>
              {bookingWarning}
            </Notification>
          )}

          <FormFooter
            formState={formState}
            editMode={editMode}
            event={event}
            error={error}
            deleteAppointment={deleteAppointment}
          />
        </form>
      </FormProvider>
    );
  };

  return <Container>{renderContent()}</Container>;
};
