import type React from "react";
import { useEffect, useState } from "react";

import {
  faArrowsToCircle,
  faClock,
  faCreditCard,
  faStickyNote,
  faUser,
  faUserPen,
} from "@fortawesome/pro-regular-svg-icons";
import { isFuture } from "date-fns";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import invariant from "ts-invariant";

import { usePostAppointmentNoShow } from "api/hooks/usePostAppointmentNoShow";
import { usePutAppointmentCompleted } from "api/hooks/usePutAppointmentCompleted";
import type { NewAppointment } from "api/schemas/NewAppointment";
import { useCalendarContext } from "contexts/CalendarContext";
import { useProfileContext } from "contexts/ProfileContext";
import { useVideoCallContext } from "contexts/VideoCallContext";
import type { Tour } from "routes/patients/PatientProfile/components/UserCourse/components/TourPlayer";
import TourPlayer from "routes/patients/PatientProfile/components/UserCourse/components/TourPlayer";
import { BaseButton } from "shared/atoms/BaseButton";
import { Notification } from "shared/atoms/Notification";
import PhoneNumber from "shared/atoms/PhoneNumber";
import Popup from "shared/atoms/Popup";
import { Tooltip } from "shared/atoms/Tooltip";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";
import useLocalizedDate, { isCurrentTimeWithin24HoursOf } from "utils/date";
import { reportError } from "utils/errorReporting";
import { userIsAdmin } from "utils/profile/profileHelper";
import { getStorageValue } from "utils/storage";

import { getAppointmentTitle } from "../../Calendar/helpers/getAppointmentTitle";
import { isAppointmentConfirmed, isAppointmentDisabled } from "../../Calendar/helpers/transformAppointments";
import { AppointmentStateIcon } from "../../shared/AppointmentStateIcon";
import { Content, Header, Label, Row, StyledFontAwesomeIcon, Value } from "../styles";

import { AppointmentState } from "./AppointmentState";

interface Props {
  appointment: NewAppointment;
  navigateToCalendar: boolean;
  onClose: () => void;
  editBooking?: () => void;
  refetchAppointments?: () => void;
}

export const AppointmentDetails: React.VFC<Props> = ({ appointment, navigateToCalendar, onClose, editBooking }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { parseISO, format } = useLocalizedDate();
  const { setCallData } = useVideoCallContext();
  const { setSelectedAppointment, setActiveForm, setSelectedPatient } = useCalendarContext();
  const { profile } = useProfileContext();
  const [showNoShowDialog, setShowNoShowDialog] = useState(false);
  const [showRescheduleDialog, setShowRescheduleDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);

  invariant(profile);

  const isAdmin = userIsAdmin(profile);
  const isTreatingTherapist = appointment.health_care_professional_id === profile.id;
  // const putAppointmentPayment = usePutAppointmentPayment();
  const putAppointmentCompleted = usePutAppointmentCompleted();
  const { mutateAsync, isLoading } = usePostAppointmentNoShow();

  const {
    health_care_professional_id,
    health_care_professional_full_name,
    health_care_provider_service_mode,
    health_care_provider_service_name,
    id,
    note,
    participants,
    payer,
    payment_method,
    // payment_state,
    purpose,
    start_time,
    end_time,
    state,
  } = appointment;

  // const isExemptionCard = payment_method === "exemption_card";
  // const isPaid = payment_state === "paid";
  // const isPhysical = health_care_provider_service_mode === "physical";
  const isPatientProfile = location.pathname.includes("/patients");
  const isConfirmed = isAppointmentConfirmed({ paymentMethod: payment_method, payerName: payer?.payer_name });

  const tour: Tour = {
    id: "appointment_details",
    steps: [
      {
        target: "#tip_mark_as_completed",
        content: (
          <TourStep>
            <Header>{t("booking.event_details.tour.mark_as_completed.title")}</Header>
            <span>{t("booking.event_details.tour.mark_as_completed.description")}</span>
          </TourStep>
        ),
        disableBeacon: true,
        disableScrolling: true,
      },
      {
        target: "#tip_mark_as_no_show",
        content: (
          <TourStep>
            <Header>{t("booking.event_details.tour.mark_as_no_show.title")}</Header>
            <span>{t("booking.event_details.tour.mark_as_no_show.description")}</span>
          </TourStep>
        ),
        disableBeacon: true,
        disableScrolling: true,
      },
    ],
  };
  const hasSeenTour = getStorageValue("tours-completed")?.appointment_details;

  useEffect(() => {
    AnalyticsService.track(AnalyticsEvents.BOOKING.APPOINTMENT.APPOINTMENT_DETAILS_OPENED, {
      appointmentID: id,
      participants: participants.map(participant => participant.id),
      therapistID: health_care_professional_id,
    });
  }, []);

  return (
    <Popup onClickOutside={onClose} fullBgOpacity noPadding={false} showCloseButton>
      {!hasSeenTour && <TourPlayer tour={tour} storeStatus />}

      <StyledContent>
        <Header>
          <AppointmentStateIcon state={state} largeMargin />
          {getAppointmentTitle({ purpose, hcpServiceName: health_care_provider_service_name })}
        </Header>
        {payment_method && (
          <Row>
            <Label>
              <StyledFontAwesomeIcon icon={faCreditCard} color={theme.colors.redesign.db90} />
              {t("booking.form.payment_method")} - {t(`booking.services.payment_methods_options.${payment_method}`)}
            </Label>
            {/* TODO: Uncomment this when we decide to show payment status again */}
            {/* {!isExemptionCard && (
              <Value>
                <Payment>
                  <PaymentState $isPaid={isPaid}>
                    {isPaid
                      ? t("booking.event_details.payment_states_paid")
                      : t("booking.event_details.payment_states_unpaid")}
                  </PaymentState>

                  {isAdmin && isPhysical && !isPaid && (
                    <BlueIconButton
                      dataTestId="mark-as-paid"
                      label={t("booking.buttons.mark_as_paid")}
                      onClick={() => {
                        putAppointmentPayment.mutateAsync(
                          {
                            appointmentId: id,
                            managerId: profile.id,
                            data: { payment_state: "paid" },
                          },
                          {
                            onSuccess: () => {
                              if (refetchAppointments) refetchAppointments();
                              // TODO: fix weird type incompatability
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              setSelectedAppointment({ ...selectedAppointment, payment_state: "paid" });
                            },
                          }
                        );
                      }}
                    />
                  )}
                </Payment>
              </Value>
            )} */}
          </Row>
        )}

        <Row>
          <Label>
            <StyledFontAwesomeIcon icon={faUserPen} color={theme.colors.redesign.db90} />
            {t("booking.event_details.personnel")}
          </Label>
          <Value>{health_care_professional_full_name}</Value>
        </Row>

        <Row>
          <Label>
            <StyledFontAwesomeIcon icon={faUser} color={theme.colors.redesign.db90} />
            {t("booking.event_details.participants")}
          </Label>
          {participants?.map(participant => {
            return (
              <Participant key={participant.id}>
                <ContactDetails>
                  <Value>{participant.full_name}</Value>
                  {isAdmin && participant.phone_number && (
                    <StyledPhoneNumber phone={participant.phone_number} country="Sweden" />
                  )}
                </ContactDetails>

                {/* Only the treating therapist is allowed to view patient profile when the appointment is confirmed */}
                {isTreatingTherapist &&
                  isConfirmed &&
                  (isPatientProfile ? (
                    <BaseButton
                      text={t("booking.buttons.view_calendar")}
                      onClick={() => {
                        navigate("/calendar");
                        setSelectedAppointment(null);
                      }}
                      variant="tertiaryBlue"
                      icon="arrow-right"
                      iconPlacement="end"
                    />
                  ) : (
                    <BaseButton
                      text={t("booking.buttons.view_profile")}
                      onClick={() => {
                        navigate(`/patients/${participant.id}`);
                        setSelectedAppointment(null);
                      }}
                      variant="tertiaryBlue"
                      icon="arrow-right"
                      iconPlacement="end"
                    />
                  ))}
              </Participant>
            );
          })}
        </Row>

        <Row>
          <Label>
            <StyledFontAwesomeIcon icon={faClock} color={theme.colors.redesign.db90} />
            {t("booking.event_details.time")}
          </Label>
          <Value>{`${format(parseISO(start_time), "PPPPp")} - ${format(parseISO(end_time), "p")}`}</Value>
        </Row>

        {health_care_provider_service_mode && (
          <Row>
            <Label>
              <StyledFontAwesomeIcon icon={faArrowsToCircle} color={theme.colors.redesign.db90} />
              {t("booking.event_details.mode")}
            </Label>
            <Value>{t(`booking.services.mode_options.${health_care_provider_service_mode}`)}</Value>
          </Row>
        )}

        {note && (
          <Row>
            <Label>
              <StyledFontAwesomeIcon icon={faStickyNote} color={theme.colors.redesign.db90} />
              {t("booking.event_details.note")}
            </Label>
            <Value>{note}</Value>
          </Row>
        )}
      </StyledContent>

      {isAppointmentDisabled(state) ? (
        <StateLabel>
          <AppointmentState state={state} />
        </StateLabel>
      ) : (
        <ButtonsContainer>
          {health_care_professional_id === profile.id &&
            health_care_provider_service_mode === "digital" &&
            isConfirmed && (
              <BaseButton
                text={t("booking.buttons.start_call")}
                onClick={() => {
                  setCallData({
                    caller: {
                      id: health_care_professional_id,
                      name: health_care_professional_full_name,
                    },
                    callee: {
                      id: participants[0].id,
                      name: participants[0].full_name ?? "",
                    },
                  });
                  navigate(`/patients/${participants[0].id}`);
                }}
                variant="tertiaryBlue"
                icon="phone"
              />
            )}

          {!isConfirmed && (
            <StateLabel>
              <ReservedLabel>{t("booking.event_details.not_confirmed")}</ReservedLabel>
            </StateLabel>
          )}

          {isTreatingTherapist && (
            <>
              <Tooltip
                title={t("booking.event_details.tooltip.mark_as_completed_disabled")}
                placement="top"
                arrow
                disabled={!isFuture(parseISO(appointment.start_time))}
              >
                <TooltipContent>
                  <BaseButton
                    id="tip_mark_as_completed"
                    text={t("booking.buttons.mark_as_completed")}
                    onClick={() => {
                      putAppointmentCompleted.mutateAsync(
                        {
                          appointmentID: id,
                        },
                        {
                          onSuccess: () => {
                            AnalyticsService.track(AnalyticsEvents.BOOKING.APPOINTMENT.MARKED_AS_COMPLETED, {
                              appointmentID: id,
                              participants: participants.map(participant => participant.id),
                              therapistID: health_care_professional_id,
                            });
                            setSelectedAppointment(null);
                          },
                          onError: e => {
                            reportError("AppointmentDetails.tsx", e);
                          },
                        }
                      );
                    }}
                    disabled={isFuture(parseISO(appointment.start_time))}
                    variant="tertiaryBlue"
                    icon="phone-arrow-up-right"
                  />
                </TooltipContent>
              </Tooltip>

              <Tooltip
                title={t("booking.event_details.tooltip.mark_as_no_show_disabled")}
                placement="top"
                arrow
                disabled={isCurrentTimeWithin24HoursOf(parseISO(appointment.start_time))}
              >
                <TooltipContent>
                  <BaseButton
                    id="tip_mark_as_no_show"
                    text={t("booking.buttons.mark_as_no_show")}
                    onClick={() => {
                      setShowNoShowDialog(true);
                    }}
                    disabled={!isCurrentTimeWithin24HoursOf(parseISO(appointment.start_time))}
                    variant="tertiaryBlue"
                    icon="phone-xmark"
                  />
                </TooltipContent>
              </Tooltip>

              {showNoShowDialog && (
                <Popup fullBgOpacity onClickOutside={() => setShowNoShowDialog(false)} showCloseButton>
                  <PopupContent>
                    <Header>{t("booking.forms.mark_as_no_show")}</Header>
                    <BaseButton
                      text={t("buttons.yes")}
                      onClick={() => {
                        mutateAsync(
                          {
                            appointmentID: id,
                            hcProfID: health_care_professional_id,
                          },
                          {
                            onSuccess: () => {
                              AnalyticsService.track(AnalyticsEvents.BOOKING.APPOINTMENT.MARKED_AS_NO_SHOW, {
                                appointmentID: id,
                                participants: participants.map(participant => participant.id),
                                therapistID: health_care_professional_id,
                              });
                              setSelectedAppointment({ ...appointment, state: "no_show" });
                              setShowNoShowDialog(false);
                              setShowRescheduleDialog(true);
                            },
                            onError: e => {
                              reportError("AppointmentDetails.tsx", e);
                              setError(t("errors.generic"));
                            },
                          }
                        );
                      }}
                      disabled={isLoading}
                      loading={isLoading}
                      uppercase
                      style={{ marginBottom: "20px" }}
                    />
                    <BaseButton
                      text={t("buttons.cancel")}
                      onClick={() => {
                        setShowNoShowDialog(false);
                      }}
                      disabled={isLoading}
                      variant="tertiaryBlue"
                      uppercase
                    />
                    {error && (
                      <Notification variant="danger" style={{ marginTop: "16px" }}>
                        {error}
                      </Notification>
                    )}
                  </PopupContent>
                </Popup>
              )}
            </>
          )}

          <BaseButton
            text={t("booking.buttons.reschedule")}
            onClick={() => {
              if (navigateToCalendar) {
                setSelectedAppointment(appointment);
                setActiveForm("book_appointment");
                navigate("/calendar");
              } else if (editBooking) {
                editBooking();
              }
            }}
            variant="tertiaryBlue"
            icon="edit"
          />
        </ButtonsContainer>
      )}

      {showRescheduleDialog && (
        <Popup fullBgOpacity onClickOutside={() => setShowNoShowDialog(false)} showCloseButton>
          <PopupContent>
            <Header>{t("booking.forms.mark_as_no_show_new_appointment")}</Header>
            <BaseButton
              text={t("buttons.yes")}
              onClick={() => {
                setSelectedAppointment(null);
                setSelectedPatient(participants[0].id.toString());
                setActiveForm("book_appointment");
                navigate("/calendar");
                setShowRescheduleDialog(false);
              }}
              disabled={isLoading}
              loading={isLoading}
              uppercase
              style={{ marginBottom: "20px" }}
            />
            <BaseButton
              text={t("buttons.cancel")}
              onClick={() => {
                setShowRescheduleDialog(false);
              }}
              disabled={isLoading}
              variant="tertiaryBlue"
              uppercase
            />
            {error && (
              <Notification variant="danger" style={{ marginTop: "16px" }}>
                {error}
              </Notification>
            )}
          </PopupContent>
        </Popup>
      )}
    </Popup>
  );
};

// const Payment = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// `;

// const PaymentState = styled.div<{ $isPaid: boolean }>`
//   padding: 4px 8px;
//   background: ${({ $isPaid, theme }) => ($isPaid ? theme.colors.pastels.green : theme.colors.pastels.orange)};
//   border-radius: 4px;
// `;

const StyledContent = styled(Content)`
  padding: 40px 26px 0;
`;

const Participant = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContactDetails = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledPhoneNumber = styled(PhoneNumber)`
  margin-left: 30px;

  ${props => props.theme.aboveBreakpoint} {
    color: ${({ theme }) => theme.colors.redesign.db90};
    font-size: 16px;
    font-weight: 400;
  }
`;

const StateLabel = styled.div`
  color: ${({ theme }) => theme.colors.redesign.db70};
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding-bottom: 32px;
`;

const ReservedLabel = styled.div`
  text-transform: uppercase;
`;

const ButtonsContainer = styled.section`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.redesign.db20};

  & > div {
    border-left: 1px solid ${({ theme }) => theme.colors.redesign.db20};
  }

  & > div > button,
  & > button {
    flex: 1;
    padding: 10px 20px;

    &:not(:first-child) {
      border-left: 1px solid ${({ theme }) => theme.colors.redesign.db20};
    }
  }

  ${({ theme }) => theme.belowBreakpoint} {
    flex-direction: column;

    & > div {
      border-top: 1px solid ${({ theme }) => theme.colors.redesign.db20};
    }

    & > div > button,
    & > button {
      padding: 16px 0;

      &:not(:first-child) {
        border-top: 1px solid ${({ theme }) => theme.colors.redesign.db20};
      }
    }
  }
`;

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 430px;
  padding: 40px 26px 32px;
  text-align: center;
`;

const TourStep = styled.div`
  text-align: left;
`;

const TooltipContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
